.info-notification-info2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    z-index: 1000;
    text-align: center;
}

.error-message2{
    font-size: 10px;
    color: red;
    text-transform: none;
    font-family: Arial, Helvetica, sans-serif;
  }

  .info-notification-info3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 500px;
    z-index: 1000;
    text-align: center;
}