.basic-info-form-grid2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
}

.basic-info-container-group {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: flex-start;
  padding: 10px;
}

.basic-info-form-group4 {
  display: flex;
  margin: 10px;
  align-items: flex-start;
  width: 80%;
}

.basic-info-form-group5 {
  padding: 5px;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.basic-info-form-group4 label {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 5px;
  text-align: left;
}

.basic-info-form-group4 select {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  color: #495057;
  width: 104%;
}

.basic-info-form-group4 input {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  color: #495057;
  width: 100%;
}

.basic-info-container-group p {
  color: #495057;
  font-size: 14px;
  text-transform: none;
}

.sale-counter {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.sale-counter button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
}

.sale-counter button:focus {
  outline: none;
}

.sale-counter span {
  margin: 0 10px;
  font-size: 12px;
  font-weight: bold;
}

.basic-footer-container-group {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: flex-end;
  padding: 10px;
}

.basic-footer-form-group {
  margin: 10px;
  align-items: flex-start;
}

.basic-footer-form-group label {
  color: #12509B;
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-center {
  margin-top: 10px;
  text-align: center;
}