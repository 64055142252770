.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 145px;
  /* Ajusta el margen izquierdo para que no se superponga con el sidebar */
  width: calc(100% - 180px);
  /* Ajusta el ancho tomando en cuenta el sidebar */
}

.form-group {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

label {
  margin-bottom: 5px;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
}

select {
  background-color: #ffffff;
}


.search-icon {
  margin-right: 8px;
  /* Espacio entre el ícono y el texto */
  font-size: 16px;
  /* Tamaño del ícono */
}

.button-add {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-end;
}
.add-module-button{
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #0d47a1;
  border-radius: 12px;
  padding: 10px 20px;
  color: #0d47a1;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
  margin-left: auto; 
}

.add-module-button:hover {
  background-color: #0d47a1;
  color: #ffffff;
}