.combos-container {
    justify-content: center;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.combo-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.combo1 {
    background-color: #0B305D;
    
}

.combo2 {
    background-color: #0D386C;
   
}

.combo3 {
   
    background-color: #0E407C;
}

.combo4 {
    background-color: #104C8C;
}

.combo-button:hover {
    opacity: 0.8;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
