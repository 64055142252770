.error-notification {
    display: flex;
    background-color: #C40C0C; 
    color: white;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    border-radius: 5px;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; 
    height: 100px;
}

.icon-container-noti-error {
    padding: 20px;
  }
  
  .icon-noti-error {
    font-size: 30px;
  }

.message-container-error{
    flex-grow: 1;
}

.title-noti{
    text-transform: uppercase; 
    font-size: 14px;
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.info-noti {
    text-transform: lowercase; 
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.info-noti::first-letter {
    text-transform: uppercase; /* Hace que solo la primera letra sea mayúscula */
}

.close-container-error {
    background-color: #af0b0b; 
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height:100px; 
    color: white; 
    margin-right: 0px;
    border-top-right-radius: 5px; 
    border-bottom-right-radius: 5px; 
}
