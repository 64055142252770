.home-container-form {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 145px;
  width: calc(100% - 180px);
}

.actions-container-form {
  display: flex;
  justify-content: flex-end;
  /* Alinea los elementos a la izquierda */
  gap: 1px;
  /* Añade espacio entre los elementos */
  flex-grow: 1;
  padding: 10px;
  width: 101%;
}

.contener-return {
  margin-top: 10px;
  /* Ajusta el valor según la distancia que quieras moverlo */
}

.main-content {
  display: flex;
  justify-content: space-between;
  /* Espacio entre los dos contenedores */
  align-items: flex-start;
  padding: 15px;
  width: 101%;
  height: 100vh;
}

.home-content-form {
  width: 43%;
  /* Ajusta el ancho del primer contenedor */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.additional-info-container {
  width: 50%;
  /* Ajusta el ancho del segundo contenedor */
  padding: 1px;
}

.status_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  padding: 0;
  z-index: 100;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
}

.status_button :hover {
  background-color: #00000038;
}


.dropdown-container {
  font-family: 'Poppins', sans-serif;
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 150px; /* Ajusta según tus necesidades */
}

.dropdown-item {
  display: block;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border: none;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #12509B; 
}

.dropdown-item:last-child {
  border-bottom: none; 
}

.dropdown-item:hover {
  background-color: #f0f0f0; 
}
