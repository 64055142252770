.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 145px; /* Ajusta el margen izquierdo para que no se superponga con el sidebar */
  width: calc(100% - 180px); /* Ajusta el ancho tomando en cuenta el sidebar */
}

.home-content {
  flex-grow: 1;
  padding: 20px;
  height: 100vh;
  
  /* Agrega más estilos según lo necesites */
}

.filter-form {
  display: flex;
  gap: 5px; /* Espacio entre los campos de entrada */
  align-items: flex-start;
}

.form-group-contract {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

label {
  margin-bottom: 5px;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
}

.contract-input, select {
  width: 150px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

select {
  background-color: #ffffff;
}



.search-icon {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
  font-size: 16px; /* Tamaño del ícono */
}

.button-add-contract {
  width: 100%; /* Asegúrate de que ocupe todo el ancho disponible */
  display: flex;
  justify-content: flex-end; /* Alinea el contenido al final (derecha) */
  align-items: center; /* Alinea verticalmente el contenido */
  padding-right: 20px; /* Añade un padding derecho si es necesario para separarlo del borde */
}

.basic-contract-button {
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #0d47a1; 
  border-radius: 8px; 
  padding: 6px 12px; 
  color: #0d47a1; 
  font-size: 11px; 
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.modal-overlay-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté sobre otros elementos */
}

.modal-content-edit {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 80%;
  overflow-y: auto; /* Permite el desplazamiento si el contenido es muy grande */
}