.custom-button {
    display: flex;
    align-items: center;
    border: 1px solid #12509B;
    border-radius: 8px;
    padding: 10px 15px;
    background-color: #ffffff;
    color: #12509B;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #12509B;
    color: white;
  }
  
  .button-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .button-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .button-dot {
    width: 8px;
    height: 8px;
    background-color: #12509B;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .button-dot:hover {
    background-color: white;
    color: white;
  }
  