.sales-table-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  margin: auto;
  overflow-x: auto;
}

.sales-table-header {
  display: flex;
  justify-content: space-between; /* Espacio entre los elementos de la cabecera */
  align-items: center;
  margin-bottom: 20px;
}

.header-left {
  display: flex;
  align-items: center;
}

.icon-container-table {
  width: 40px;
  height: 40px;
  background-color: #12509B; /* Color del ícono */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon {
  color: white;
  font-size: 24px;
}

h3 {
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  color: #3d4a5c;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  flex-direction: column;
}

.header-right .search-input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 12px;
  color: #495057;
}

.header-right .search-input::placeholder {
  color: #adb5bd; /* Color más claro para el placeholder */
  opacity: 1; /* Asegura que el placeholder sea completamente visible */
}

.sales-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.sales-table thead {
  font-weight: bolder;
  background-color: #f9f9f9; /* Fondo claro para los encabezados */
}

.sales-table th {
  padding: 15px;
  text-align: left;
  font-weight: normal;
  color: #6c757d; /* Color de texto más claro */
  text-transform: uppercase; /* Texto en mayúsculas */
  font-size: 10px; /* Tamaño de letra más pequeño */
}

.sales-table tbody tr {
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.sales-table td {
  padding: 15px;
  font-size: 12px; /* Tamaño de letra estándar */
  color: #495057; /* Color de texto más oscuro */
}

.flag-column {
  font-size: 24px;
}

.label {
  font-weight: bold;
  color: #3d4a5c;
}

/* Estilo adicional para que se vea más parecido a la imagen */
.sales-table td {
  vertical-align: middle; /* Alinea verticalmente el contenido de las celdas */
}

.sales-table .product-column {
  display: flex;
  align-items: center;
}

.sales-table .product-column img {
  border-radius: 50%;
  margin-right: 15px;
}

.button-container {
  display: flex;
  gap: 10px; /* Espacio entre los botones */
}

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 2px solid #0d47a1; /* Ajusta el color del borde */
  border-radius: 50%; /* Bordes redondeados */
  padding: 12px 12px; /* Espaciado interno */
  color: #0d47a1; /* Color del icono */
  font-size: 12px;
  z-index: 100;
  cursor: pointer;
  width: 40px;
  transition: background-color 0.3s ease;
}

.icon-button:hover {
  background-color: #0d47a1;
  color: white;
} 

/*.edit-button {
  background-color: #c3e6cb;
} */

/*.delete-button {
  background-color: #f8d7da;
} */

/* .delete-button:hover {
  background-color: #f5c6cb;
} */

/*.company-button {
  background-color: #bee5eb;
  color: #0c5460;
} */

/*.company-button:hover {
  background-color: #abdde5;
} */

.custom-tooltip {
  background-color: #4b5563 !important; /* Cambia el color de fondo del tooltip */
  color: white !important; /* Cambia el color del texto del tooltip */
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  border-radius: 5px; /* Añade bordes redondeados si lo deseas */
  font-size: 10px !important; /* Tamaño de fuente del tooltip */
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

/* Opcional: Cambia el color de la flecha del tooltip */
.custom-tooltip .react-tooltip-arrow {
  color: #4caf50 !important;
}

.no-data {
  text-align: center; /* Centra el texto horizontalmente */
  vertical-align: middle; /* Centra el texto verticalmente */
  height: 100px; /* Altura suficiente para centrar verticalmente */
  font-weight: bold; /* Opcional: Hace el texto más visible */
  color: #6c757d; /* Opcional: Añade un color gris al texto */
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  text-transform: uppercase; /* Texto en mayúsculas */
}

.sales-table tbody tr:hover {
  background-color: #f1f3f5; /* Cambia el color de fondo al pasar el mouse */
  cursor: pointer; /* Cambia el cursor al pasar sobre la fila */
}

.sales-table td {
  transition: background-color 0.3s ease; /* Añade una transición suave para el cambio de color */
}

.shortcut-icon-actually {
  background-color: #12509B; /* Fondo del ícono */
  color: white; /* Color del ícono */
  border-radius: 50%;
  padding: 8px; /* Ajusta el tamaño del ícono */
  margin: 15px; /* Espacio entre el ícono y el texto */
  font-size: 14px; /* Tamaño del ícono */
}

/* Estilo para la fila seleccionada */
.selected-row {
  background-color: #f1f3f5; /* Color de fondo para la fila seleccionada */
  border-left: 4px solid #12509B; /* Añade un borde a la izquierda para resaltar más la fila */
}
@media (max-width: 768px) {
  .sales-table-header {
    flex-direction: column; /* Cambia la dirección a columna en pantallas pequeñas */
    align-items: flex-start; /* Alinea los elementos al inicio */
  }

  .header-left {
    margin-bottom: 10px; /* Añade un margen inferior para separar los elementos */
  }

  .header-right .search-input {
    width: 100%; /* Haz que el input de búsqueda ocupe todo el ancho disponible */
    margin-top: 10px; /* Añade un margen superior */
  }

  .icon-container-table {
    width: 30px; /* Reduce el tamaño del ícono en pantallas pequeñas */
    height: 30px;
  }

  .shortcut-icon-actually {
    padding: 6px; /* Reduce el tamaño del ícono */
    margin: 10px;
    font-size: 12px;
  }

  .sales-table th,
  .sales-table td {
    padding: 10px;
    font-size: 12px; /* Disminuye el tamaño de la fuente */
  }

  .sales-table td {
    padding: 8px; /* Ajusta el padding */
  }
}
@media (max-width: 576px) {
  .sales-table th:nth-child(3), /* Cambia el número para seleccionar la columna que quieres ocultar */
  .sales-table td:nth-child(3) {
    display: none; /* Oculta la columna */
  }
}
@media (max-width: 576px) {
  .sales-table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  .sales-table tbody tr {
    display: block;
    margin-bottom: 15px;
  }

  .sales-table td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .sales-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
}


