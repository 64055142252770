.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  position: relative;
  height: 40px;
  border-radius: 15px;
  background-color: #12509B;
  color: #ffffff;/* Ajusta el ancho según sea necesario */
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.breadcrumb-item {
  margin-left: 5px;
  color: white;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase; 
  flex-direction: column;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-content h2 {
  font-size: 18px;
  margin-right: 20px;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
}

.header-actions {
  display: flex;
  align-items: center;
}

.search-input {
  background-color: transparent;
  border: 1px solid #4b5563;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  margin-right: 20px;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
}

.search-input::placeholder {
  color: white;
}
.shortcut-button {
  display: flex;
  align-items: center;
  background-color: rgba(10, 50, 100, 0.7); /* Color de fondo similar al de la imagen */
  border: none;
  border-radius: 25px; /* Bordes redondeados */
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.shortcut-button:hover {
  background-color: rgba(14, 42, 77, 0.7); /* Un poco más oscuro para el efecto hover */
}

.shortcut-icon {
  background-color: #12509B; /* Fondo del ícono */
  color: white; /* Color del ícono */
  border-radius: 50%;
  padding: 8px; /* Ajusta el tamaño del ícono */
  margin-right: 5px; /* Espacio entre el ícono y el texto */
  font-size: 14px; /* Tamaño del ícono */
}

.shortcut-button span {
  color: white; /* Color del texto */
  font-weight: bold;
}

.icons {
  font-size: 20px;
  margin-right: 10px;
  color: white;
}
