.line-break {
    display: block;
    height: 2px; 
    width: 100%;
  }
  
.module_item-container2 {
    min-width: 250px;
    height: 80px;
    position: relative;
    color: white;
    border-radius: 5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.205);
    display: flex;
    align-items: center; 
    justify-content: start; 
    padding: 15px; 
    cursor: pointer; 
    transition: transform 0.3s ease; 
}

.module_item-container2 p {
    margin-left: 10px; 
    font-size: 16px;
}

.module_item-container2:hover {
    transform: translateY(-5px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
}
