
.filter-form {
  display: flex;
  align-items: flex-start;
}


label {
  margin-bottom: 5px;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
}

.contract-input, select {
  width: 200px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

select {
  background-color: #ffffff;
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #12509B;
  color: #6c757d; /* Color del texto similar al de la imagen */
   /* Borde con color similar al texto */
  border-radius: 5px; /* Bordes redondeados */
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  top: 30px; /* Mueve el botón 20px hacia abajo */
}

.search-button:hover {
  background-color: white;
  color: #12509B;
}

.search-icon {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
  font-size: 16px; /* Tamaño del ícono */
}

.button-add-contract {
  width: 20px;
  display: flex;
  gap: 10px; 
  align-items: flex-start;
  justify-content: flex-end;
}

.basic-contract-button {
    display: inline-flex;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #0d47a1; 
    border-radius: 8px; 
    padding: 6px 12px; 
    color: #0d47a1; 
    font-size: 11px; 
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reducir la sombra para ajustarse al nuevo tamaño */
    transition: background-color 0.3s, color 0.3s;
}

.icon-red {
  color: red;
}

.icon-green {
  color: green;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  width: 36px;
  height: 36px;
  background: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  max-width: 100px;
  font-size: 10px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  margin-top: 4px;
  border-radius: 4px;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}