.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 145px; /* Ajusta el margen izquierdo para que no se superponga con el sidebar */
  width: calc(100% - 180px); /* Ajusta el ancho tomando en cuenta el sidebar */
}
.form-group {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

label {
  margin-bottom: 5px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
}

.customer-input,
select {
  width: 200px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.search-button-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #12509b;
  color: #6c757d; /* Color del texto similar al de la imagen */
  /* Borde con color similar al texto */
  border-radius: 5px; /* Bordes redondeados */
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  top: 12px; /* Mueve el botón 20px hacia abajo */
  left: 20px;
}

select {
  background-color: #ffffff;
}

.search-icon {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
  font-size: 16px; /* Tamaño del ícono */
}
.section-container-form {
  background-color: white;
  padding: 1px;
  margin-bottom: 1px;
  text-align: left; /* Asegura que todo el contenido esté alineado a la izquierda */
}

.section-title-form {
  font-size: 14px;
  font-weight: bold;
  color: #12509b;
  margin-bottom: 5px;
  text-align: left; /* Asegura que el título esté alineado a la izquierda */
}

.section-divider-form {
  border: none;
  border-bottom: 2px solid #dcdcdc;
  margin: 1px 0;
}

.form-group-form {
  margin-bottom: 15px;
  position: relative;
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  text-align: left; /* Asegura que el contenido esté alineado a la izquierda */
}
