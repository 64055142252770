.section-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  margin: auto;
  margin-top: 1px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Asegura que ambos elementos estén alineados verticalmente */
}