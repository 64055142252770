.basic-info-form-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase; 
}

.basic-info-form-title {
    font-size: 18px;
    margin-bottom: 20px;
}

.basic-info-form-grid {
    width: 98%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    position: relative;
}

.basic-info-form-group2 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: flex-start;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); 
    padding: 15px; 
    border-radius: 5px; 
}

.basic-info-form-group2 label {
    font-size: 14px;
    color: #000102;
    margin-bottom: 5px;
    text-align: left;
}

.basic-info-form-group2 input,
.basic-info-form-group2 select {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    color: #495057;
    width: 100%;
}

.basic-info-date-picker {
    width: 100%;
    display: flex;
    gap: 10px;
}

.basic-info-date-picker select {
    flex: 1;
    width: 100%;
}

/* Estilo del botón personalizado */
.basic-form-footer {
    gap: 15px;
    display: flex;
    justify-content: flex-end; /* Alinea el botón al lado derecho */
    margin-top: 25px;
}

.basic-custom-button {
    display: inline-flex;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #0d47a1; 
    border-radius: 12px;
    padding: 10px 20px;
    color: #0d47a1; 
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
}

.basic-custom-button:hover {
    background-color: #0d47a1;
    color: #ffffff;
}

.basic-button-icon {
    margin-right: 8px; /* Espacio entre el icono y el texto */
    font-size: 16px; /* Tamaño del icono */
}

.custom-date-picker {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 12px;
    color: #0d47a1;
    width: 100%;
}


.basic-shortcut-icon {
    background-color: #12509B; /* Fondo del ícono */
    color: white; /* Color del ícono */
    border-radius: 50%;
    padding: 8px; /* Ajusta el tamaño del ícono */
    margin-right: 5px; /* Espacio entre el ícono y el texto */
    font-size: 15px; /* Tamaño del ícono */
}

.basic-info-form-group3 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: flex-start;
  }
  .basic-info-form-switch {
    display: flex;
    flex-direction: column;
    margin-top: 10px; /* Aumenta el valor para moverlo más abajo */
    align-items: flex-start;
}
    .basic-info-form-switch label {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .basic-info-form-group3 label {
    font-size: 14px;
    color: #000000;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .basic-info-form-group3 input,
  .basic-info-form-group3 select {
    text-align: center;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    color: #495057;
    width: 50%;
  }

  .icon-button-desc-asc {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 2px solid #0d47a1; /* Ajusta el color del borde */
    border-radius: 50%; /* Bordes redondeados */
    padding: 12px 12px; /* Espaciado interno */
    color: #0d47a1; /* Color del icono */
    font-size: 8px;
    z-index: 100;
    cursor: pointer;
    width: 35px;
    transition: background-color 0.3s ease;
  }
  .icon-button-desc-asc:hover {
    background-color: #0d47a1; /* Cambio de color al hacer hover */
    color: #ffffff; /* Color del icono al hacer hover */
  }