.header-right {
    display: flex; 
    gap: 10px; 
}
.basic-table-button {
    display: inline-flex;
    flex-direction: column; 
    align-items: center;   
    justify-content: center; 
    background-color: #ffffff;
    border: 2px solid #0d47a1; 
    border-radius: 12px;
    padding: 5px 10px;
    color: #0d47a1; 
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
    text-align: center; 
}

.basic-table-button:hover {
    background-color: #0d47a1;
    color: #ffffff;
}

.button-line {
    display: block; 
}
