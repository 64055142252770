.basic-info-form-group-add {
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: flex-start;
  position: relative;
}

.basic-info-form-group-add label {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 5px;
  text-align: left;
}

.input-chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-chip-container input {
  border: none;
  flex: 1;
  outline: none;
  min-width: 150px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.chip {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
}

.chip-close {
  margin-left: 8px;
  cursor: pointer;
  color: #ff4d4f;
  font-weight: bold;
}

.search-results2 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.search-results2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results2 li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.search-results2 li:hover {
  background-color: #12509b;
}


.user-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espacio entre los usuarios */
  margin-bottom: 15px;
}

.module-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px; /* Espacio entre los usuarios */
  margin-bottom: 15px;
}

.user-chip {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}