/* SliderButton.css */

.slider-container {
    display: flex;
    width: 200px;
    border: 2px solid #12509B;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .slider-option {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    transition: all 0.3s ease;
    text-transform: none; 
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
  
  .active {
    background-color: #12509B;
    color: white;
  }
  
  .inactive {
    background-color: #f0ecec;
    color: #12509B;
  }
  
  .react-datepicker__input-container{
    width: 100%;
  }

  .basic-info-form-group4 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: flex-start;
  }
  
  .basic-info-form-group4 label {
    font-size: 14px;
    color: #000000;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .basic-info-form-group4 input,
  .basic-info-form-group4 select {
    text-align: left;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    color: #495057;
    width: 100%;
  }