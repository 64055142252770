.modal-content-module{
    width: 90%;
    height: 90%;
    background-color: white;
    border-radius: 1%;
    padding: 10px;
}

.modal-content-module-body{
    width: 100%;
    height: 80%;
}

.modal-title {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sale-counter2 {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #f0f0f0; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    width: 50%;
  }
  
  .sale-counter2 button {
    background-color: transparent; 
    border: none; 
    font-size: 14px; 
    cursor: pointer;
    padding: 5px 10px; 
  }
  
  .sale-counter2 button:focus {
    outline: none; 
  }
  
  .sale-counter2 span {
    margin: 0 10px; 
    font-size: 12px; 
    font-weight: bold; 
  }