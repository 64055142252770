.info-notification-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.info-notification-info {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    z-index: 1000;
    text-align: center;
}

.icon-container-noti-info {
    margin-bottom: 15px;
}

.icon-noti2 {
    font-size: 2rem;
    color: #007bff;
}
.message-container-info  {
    margin-top: 5px;
    margin-bottom: 30px;
}
.message-container-info h4 {
    margin-bottom: 10px;
    font-size: 1.25rem;
}

.message-container-info p {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #333;
}

.button-container-info {
    display: flex;
    justify-content: space-around;
}

.cancel-btn-info, .ok-btn-info {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.button-container-info button:hover {
    background: #ddd;
    color: black;
  }

.cancel-btn-info {
    background-color: #dc3545;
    color: #fff;
}

.ok-btn-info {
    background-color: #12509B;
    color: #fff;
    
}
