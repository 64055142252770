/* Contenedor para el loader que cubre toda la pantalla */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.103); /* Fondo oscuro con transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que esté por encima de todo el contenido */
}

/* Estilos para el GIF */
.loader-gif {
  width: 362px; /* Ajusta el tamaño del GIF según lo que necesites */
  height: 182px;
}
