.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 145px;
    width: calc(100% - 180px);
}

.home_modules-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    margin: 20px;
    padding: 20px;
}

.module_item-container {
    flex: 1 1 30%;
    min-width: 280px;
    height: 200px;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.205);
}

.small-module {
    width: 50px; 
    height: 50px; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -25px; 
    left: 20%; 
    transform: translateX(-50%);
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icono {
    width: 25px; 
    height: 25px;
}

.content {
    padding: 20px;
    background-color: #fff;
    text-align: center;
}

.content-header {
    justify-content: center;
    align-items: center;
    text-align: right;
}

.content-title {
    font-size: 17px;
}

.content-count {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: bold;
}

hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
}

/* Media Queries */
@media (max-width: 768px) {
    .home-container {
        margin-left: 0;
        width: 100%;
    }

    .module_item-container {
        flex: 1 1 45%;
        min-width: 220px;
    }
}

@media (max-width: 480px) {
    .home_modules-container {
        flex-direction: column;
    }

    .module_item-container {
        flex: 1 1 100%;
        min-width: 100%;
    }

    .small-module {
        width: 40px;
        height: 40px;
        top: -20px;
    }

    .content {
        padding: 10px;
    }

    .content-title {
        font-size: 15px;
    }

    .content-count {
        font-size: 18px;
    }
}
