.icon-noti3 {
    font-size: 2rem;
    color: #399918;
}

reg-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column; /* Mantiene los registros uno debajo del otro */
    align-items: flex-start; /* Alinea los registros a la izquierda */
    justify-content: flex-start; /* Justifica el contenido a la izquierda */
    width: 100%; /* Ocupa el 100% del ancho disponible */
    text-align: justify; /* Justifica el texto */
}

.reg-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Deja espacio entre el texto y el ícono de copiar */
    margin-bottom: 5px;
    width: 100%; /* Asegura que el registro ocupe todo el ancho disponible */
    text-align: justify; /* Justifica el texto del registro */
}

.info-notification-info-overlay-contract {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Ancho completo de la pantalla */
    height: 100vh; /* Alto completo de la pantalla */
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    z-index: 1000;
}

.info-notification-inf-contract {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px; /* Ancho máximo */
    z-index: 1000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-wrap: break-word; /* Permite que las palabras largas se dividan en varias líneas */
    overflow-wrap: break-word; 
    max-height: 400px;
    overflow-y: auto; /* Agrega scroll si el contenido es muy largo */
}

.button-container-info {
    margin-top: 15px;
}

.message-content {
    max-height: 150px;  /* Limita la altura del mensaje para evitar que desborde */
    overflow-y: auto;  /* Agrega scroll si el mensaje es demasiado largo */
    word-wrap: break-word;  /* Rompe las palabras largas si es necesario */
    text-align: center;  /* Centra el mensaje */
    padding: 5px 10px;  /* Agrega espacio para que se vea más ordenado */
}

.message-container-info h4 {
    margin-bottom: 10px; /* Espaciado entre el título y el mensaje */
}

.reg-content {
    max-width: 400px;
    max-height: 100px;
    white-space: pre-wrap;
    overflow-y: auto;  /* Agrega scroll si es necesario */
    word-wrap: break-word;  /* Rompe palabras largas */
    white-space: normal;  /* Permite que el texto haga saltos de línea */
    text-align: left;  /* Alineación más natural */
    padding: 5px 10px;  /* Agrega espacio para que no se vea pegado */
}