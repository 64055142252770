.home-container2 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 97% ;
  }
  
  .moduleNew-form-container2 {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 40%;
    margin: 50px auto;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    text-align: center;
}

.text-box-login2 {
  flex: 1;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 14px;
}

.password-toggle-icon2 {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000000;
}