/* ShortcutsModal.css */
.shortcuts-modal-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Fondo transparente */
  z-index: 1000;
  pointer-events: none; /* Permite hacer clic a través del overlay */
}

.shortcuts-modal-container {
  position: absolute;
  background-color: #ffffff; /* Fondo blanco */
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  top: 50px; /* Ajusta esta distancia según sea necesario */
  right: 0; /* Se alineará con el lado derecho del icono */
  pointer-events: auto; /* Permite hacer clic dentro del modal */
}

.shortcut-icon {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
  color: #555; /* Color del ícono */
}


.shortcuts-modal-header {
  text-align: center;
  margin-bottom: 10px;
}

.shortcuts-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shortcut-item {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.shortcut-key {
  color: #333;
  font-size: 12px;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase; 
}
