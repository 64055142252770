.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    width: 90%;
    height: 80%;
    overflow-y: auto;
  }

  .modal-body{
    padding: 10px;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-header {
    margin-bottom: 10px;
  }
  
  .modal-search {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .notification {
    position: fixed; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    z-index: 1000; 
  }
  .notification-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .notification-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .notification-buttons button:hover {
    background: #ddd;
  }

  .search-button-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #12509B;
    color: #6c757d; /* Color del texto similar al de la imagen */
     /* Borde con color similar al texto */
    border-radius: 5px; /* Bordes redondeados */
    padding: 8px 16px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
    top: 35px; 
    left: 20px;
  }
  
  .search-button-modal:hover {
    background-color: white;
    color: #12509B;
  }