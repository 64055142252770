.search-results {
    position: absolute;
    top: 100%; /* Coloca la lista justo debajo del input */
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 4px;
    max-height: 100px; /* Limita la altura máxima para que no crezca indefinidamente */
    overflow-y: auto; /* Agrega scroll si hay demasiados resultados */
    z-index: 10; /* Asegúrate de que la lista esté sobre otros elementos */
    max-width: 550px;
}

.search-results ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.search-results li {
    padding: 10px;
    cursor: pointer; /* Cambia el cursor a puntero cuando pases el mouse */
}

.search-results li:hover {
    background-color: #007bff; /* Color azul al hacer hover */
    color: white; /* Cambia el color del texto a blanco */
}