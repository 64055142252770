.warning-notification {
    display: flex;
    background-color: #E9C46A; 
    color: white;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    border-radius: 5px;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; 
    height: 100px;
}

.icon-container-noti-warnning {
   padding: 20px;
  }
  
  .icon-noti-warnning {
    font-size: 30px;
  }

.message-container-warnning{
    flex-grow: 1;
}

.message-container-warnning h4 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 18px;
}

.message-container-warnning p {
    margin: 0;
}

.close-container-warnning{
    background-color: #e2b84d; 
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height:100px; 
    color: white; 
    margin-right: 0px;
    border-top-right-radius: 5px; 
    border-bottom-right-radius: 5px; 
}

